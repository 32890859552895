import styled from "styled-components"

export const Svg = styled.svg`
  margin-right: ${({ marginRight }) => marginRight}px;
  transform: scale(1.5);
  @media (max-width: 460px) {
    transform: scale(1);
  }
`

export const MaxWidth = styled.div`
  max-width: 500px;
  width: 100%;
`

export const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Space = styled.div`
  margin-bottom: 18px;
`

export const Height = styled.div`
  height: 100%;
  min-height: calc(100vh - 320px);
  background-color: #eeeceb;
  display: grid;
  place-items: center;
  padding: 50px;
`

export const H1 = styled.h1`
  font-weight: 400;
  font-size: 39px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: #222222;
  margin-bottom: 48px;
  @media (max-width: 460px) {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
  }
`

export const P = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #484848;
  @media (max-width: 460px) {
    font-size: 16px;
    line-height: 24px;
  }
`
